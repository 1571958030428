import Home from './Home';
import React from 'react';
import Nasa from './Nasa';
import AnotherPage from './AnotherPage';
import "./Pages.css"
import {Route, Routes, BrowserRouter} from "react-router-dom";

//Returns all the pages, to be put into app.js

function Pages() {
  return (
    <body className='body'>
      <BrowserRouter >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/anotherpage" element={<AnotherPage />} />
          <Route path="/nasa" element={<Nasa />} />
        </Routes>
      </BrowserRouter>
    </body>
  )
}

export default Pages