import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { NavLink } from 'react-router-dom'

function Nasa() {
    //works when initial state is an empty object
    const [nasaData, setNasaData] = useState([]);

    useEffect(() => {
        getNasaInfo();
    }, [])
    const getNasaInfo = async () => {
        const check = localStorage.getItem('nasaData');
        //You need to change this check to include, to change it when the day has changed.
        if(check) {
            //when you get the string you need to pull it back into the object or array whatever. opposite of json.stringify
            setNasaData(JSON.parse(check));
        }
        else {
            const api = await fetch(`https://api.nasa.gov/planetary/apod?api_key=${process.env.REACT_APP_NASA_API}`);
            const data = await api.json();
            //local storage can only save strings, so you need to stringify it 
            localStorage.setItem('nasaData', JSON.stringify(data));
            console.log(data);
            setNasaData(data);
        }
    }
    return (
    <div className='something'>
        <h1>Nasa picture of the day</h1>
        <NavLink to="/">
            <Button variant="contained" to="/">
                Go Home
            </Button>
        </NavLink>
        <br />
        <h2>{nasaData.title}</h2>
        <img src={nasaData.url} alt={nasaData.title}/>
        <h5>For now, if the picture hasn't changed depending on the day, reload with control f5</h5>
    </div>
  )
}
export default Nasa