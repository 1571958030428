import Hero from "../components/Hero";
import React from 'react'

function Home() {
  return (
    <>
        <Hero />
    </>
  )
}

export default Home