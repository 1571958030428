import logo from "../logo.svg";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
const Hero = () => {
  const handleHelloWorld = () => {
    alert("You clicked on hello world");
  };

  return (
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p>
        Welcome to Jerin's Portfolio Website <br />
        under construction
      </p>
      <a
        className="App-link"
        href="https://jerinabraham.great-site.net"
        target="_blank"
        rel="noopener noreferrer"
      >
        Link to a normal static website
      </a>
      <br />
      <Link to="/nasa">
        <Button variant="contained">
          Nasa Information
        </Button>
      </Link>
    </header>
  );
};
//you can only have one of these
export default Hero;
