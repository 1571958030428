import React from "react";
import Pages from "./pages/Pages";
import "./App.css";
function App() {
  return (
    <>
      <Pages />
    </>
  );
}

export default App;
